import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import classnames from 'classnames'
import { Container } from 'components/layout/Grid/'
import Card from 'components/Card'
import styles from './JobSummaryBanner.scss'

const propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number,
    suffix: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
}

const JobSummaryBanner = ({
  history,
  match: { url },
  tabs,
}) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const routes = tabs.map((tab) => {
    let link = url
    if (url.slice(-1) === '/') {
      link = url.slice(0, -1)
    }
    return `${link}/${tab.suffix}`
  })

  const selectSummaryTab = (index) => {
    setSelectedTab(index)
    history.replace(routes[index])
  }

  useEffect(() => {
    const { location: { pathname } } = history
    if (pathname === url || `${pathname}/` === url) {
      selectSummaryTab(0)
    } else {
      const index = routes.findIndex(route => route === pathname)
      // prevent double push to history stack
      if (index !== selectedTab) {
        selectSummaryTab(index)
      }
    }
  })

  return (
    <div className={styles.base}>
      <Container className={styles.container}>
        {tabs.map((tab, index) => (
          <Card
            className={classnames(styles.summaryCard, index === selectedTab ? styles.selected : '')}
            key={tab.title}
            onClick={() => selectSummaryTab(index)}
          >
            <div className={classnames(styles.count)}>{tab.count}</div>
            <div className={classnames(styles.title)}>
              {tab.title}
              {tab.count !== 1 ? 's' : ''}
            </div>
          </Card>
        ))}
      </Container>
    </div>
  )
}

JobSummaryBanner.propTypes = propTypes

export default withRouter(JobSummaryBanner)
