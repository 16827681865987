import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@deloitte/gel-library'
import LabelForm from 'actionHub/views/LabelForm'
import { isConditionallyDisabledFromLabelValues, prepareValueForComparison } from 'actionHub/utils/dependencies'
import { isNullOrWhiteSpace } from 'utils/strings'
import { useSelector } from 'react-redux'
import { MODAL_BULK_ACTION_UPDATES } from 'constants/forms'
import SubmitButton from 'components/SubmitButton'
import Checkbox from 'components/Form/components/Checkbox'
import styles from './CaseTestingConclusion.scss'
import Avatar from '../Avatar'

const propTypes = {
  actionSetId: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({
    comment: PropTypes.string,
  })),
  disabled: PropTypes.bool.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })),
  object: PropTypes.object.isRequired,
  selectedTaskCount: PropTypes.number,
  setLabelValue: PropTypes.func.isRequired,
  submitLabels: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
  updatedUser: PropTypes.object,
  validateLabel: PropTypes.func.isRequired,
}

const defaultProps = {
  comments: [],
  members: [],
  selectedTaskCount: 0,
  updatedUser: null,
}

function CaseTestingConclusion({
  actionSetId, taskId, validateLabel, setLabelValue, disabled, object, selectedTaskCount, submitLabels, updatedUser, comments, members,
}) {
  const modals = useSelector(state => state.modals)
  const isBulkUpdateModal = MODAL_BULK_ACTION_UPDATES in modals && modals[MODAL_BULK_ACTION_UPDATES].show
  const [checked, setIsChecked] = useState(false)

  if (!object) {
    return null
  }

  function applyValidationValues(obj) {
    if (!obj.labels) { return [] }

    return obj.labels.map((l) => {
      if (l.dependencies === null) {
        return l
      }

      const newDependencies = l.dependencies.map((d) => {
        if (d.dataColumnName === null && d.labelName === null) {
          return d
        }
        if (d.dataColumnName !== null) {
          return {
            ...d,
            validationValue: obj.taskData && prepareValueForComparison(l.labelType, obj.taskData[d.dataColumnName]),
          }
        }
        return {
          ...d,
          validationValue: obj.taskData && obj.labels.find(label => label.name === d.labelName)?.value,
        }
      })

      return {
        ...l,
        dependencies: newDependencies,
      }
    })
  }

  const labelsWithValidationData = applyValidationValues(object)
    .map(l => ({
      ...l,
      isDisabled: isConditionallyDisabledFromLabelValues(l, object.labels),
      isComplete: !isNullOrWhiteSpace(l.value),
    }))

  const areLabelsValid = object.labels && object.labels.every(l => !('valid' in l) || l.valid)

  const handleSubmit = () => {
    submitLabels()
  }

  return (
    <div className={styles.container}>
      <div className={styles.labelContent}>
        {object.labels && (
          <LabelForm
            actionSetId={actionSetId}
            comments={comments}
            disabled={disabled}
            isModified={object._isLabelsModified}
            labels={labelsWithValidationData}
            members={members}
            setLabelValue={setLabelValue}
            taskId={taskId}
            validateLabel={validateLabel}
          />
        )}
      </div>

      <div className={styles.buttonRow}>
        {!isBulkUpdateModal && (
          <div className={styles.footerContent}>
            {updatedUser && (
              <div className={styles.updatedUser}>
                <div>Complete by</div>
                <Avatar size="medium" userDetails={updatedUser.userDetails} />
              </div>
            )}

            <Button
              disabled={!object._isLabelsModified || object._isSubmittingLabels || !areLabelsValid || disabled}
              onClick={handleSubmit}
            >
              SAVE
            </Button>
          </div>
        )}
        {isBulkUpdateModal && (
          <>
            <div className={styles.checkboxDiv}>
              <Checkbox
                checked={checked}
                className={styles.checkboxText}
                handleChange={() => setIsChecked(!checked)}
                label="Confirm that you want to bulk update selected cases. Any fields filled out will override existing values in the cases you have selected."
                name="bulkUpdateInfo"
              />
            </div>
            <div className={styles.bulkUpdateSubmitBtn}>
              <SubmitButton
                disabled={!object._isLabelsModified || object._isSubmittingLabels || !areLabelsValid || disabled || !checked}
                onClick={handleSubmit}
                submittingText="Applying"
              >
                {`UPDATE ${selectedTaskCount} ENTITIES`}
              </SubmitButton>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

CaseTestingConclusion.propTypes = propTypes
CaseTestingConclusion.defaultProps = defaultProps

export default CaseTestingConclusion
