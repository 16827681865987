/* eslint-disable react/no-array-index-key */

import * as React from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { withRouter, Switch } from 'react-router-dom'
import { push } from 'connected-react-router'
import HeaderError from 'components/Header/HeaderError'
import Header from 'components/Header/Header'
import Loading from 'components/Loading'
import Footer from 'components/Footer/Footer'
import classnames from 'classnames'
import RouteWithSubRoutes from 'components/RouteWithSubRoutes'
import ScrollToTop from 'components/ScrollToTop'
import * as jobActions from 'actions/job'
import * as appActions from 'actions/app'
import * as supportActions from 'actions/support'
import { adminFetchActiveBanners } from 'actions/admin'
import Snackbar from 'react-toolbox/lib/snackbar'
import { getConfig } from 'utils/config'
import { getCookie } from 'utils/cookies'
import { filterBannersByAudience } from 'utils/users'
import constants from 'constants'
import SupportRequestSentDialog from 'components/SupportRequestSentDialog'
import HostedReportModal from 'components/HostedReportModal'
import EditJobNameModal from 'components/EditJobNameModal'
import { useEffect, useState } from 'react'
import useScript from 'hooks/useScript'

const propTypes = {
  activeBanners: PropTypes.object.isRequired,
  app: PropTypes.shape({
    bootstraping: PropTypes.bool,
    notify: PropTypes.shape({
      active: PropTypes.bool,
      alertType: PropTypes.string,
      message: PropTypes.string,
      timeout: PropTypes.number,
    }),
    user: PropTypes.object,
  }).isRequired,
  clearNotification: PropTypes.func.isRequired,
  hideSanboxModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  jobs: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  push: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string,
  })).isRequired,
  support: PropTypes.shape({
    _contactFormSentDialogVisible: PropTypes.bool,
  }).isRequired,
  supportContactFormDialogDismissed: PropTypes.func.isRequired,
  whoami: PropTypes.func.isRequired,
}

const App = ({
  location: { pathname },
  history,
  whoami,
  support,
  supportContactFormDialogDismissed,
  app: { bootstraping, notify, user },
  routes,
  redirect,
  jobs,
  push: pushAction,
  clearNotification,
  activeBanners,
}) => {
  const dispatch = useDispatch()
  const [hasLoaded, setHasLoaded] = useState(false)
  const { WORDMARK, OT_CODE, OT_ENV_SUFFIX } = getConfig()
  // One Trust Cookie Management Code
  const otCode = OT_CODE + (OT_ENV_SUFFIX || '')
  useScript('https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    { type: 'text/javascript', charset: 'utf-8', 'data-domain-script': otCode })
  useScript('',
    { type: 'text/javascript', innerText: 'function OptanonWrapper() { }' })

  useEffect(() => {
    const { WORDMARK: insideWordmark } = getConfig()
    document.title = insideWordmark
    dispatch(adminFetchActiveBanners())
  }, [])

  useEffect(() => {
    if (pathname !== '/' && pathname !== '/signin' && !user._isFetched) {
      whoami()
    }
  }, [pathname, user._isFetched, whoami])

  useEffect(() => {
    if (!hasLoaded) {
      setHasLoaded(true)

      const path = localStorage.getItem(constants.LOCAL_STORAGE_KEYS.CURRENT_PATH)
      if (path) {
        localStorage.removeItem(constants.LOCAL_STORAGE_KEYS.CURRENT_PATH)
        history.replace(path)
      } else if (pathname === '/') {
        const activeCookie = getCookie('active')
        if (activeCookie) {
          history.replace('/home')
        } else {
          history.replace('/signin')
        }
      }
    }
  }, [hasLoaded, history, pathname])

  const hideSnackbar = () => {
    clearNotification()
  }

  const banners = activeBanners ? filterBannersByAudience(Object.values(activeBanners), user) : []

  const isReportPage = pathname.indexOf('/report/') === 0
    || (pathname.indexOf('/insights/') === 0 && pathname.indexOf('/report/') > 0)
    || (pathname.indexOf('/custom/') === 0 && pathname.indexOf('/report/') > 0)
  const isLandingPage = pathname.indexOf('/signin') === 0
      && !getCookie('active')
  const isActionHubPage = pathname.indexOf('/action') === 0

  const showFooter = !isActionHubPage && !isReportPage
  const showHeaderAndFooter = !isLandingPage && !isActionHubPage && !isReportPage

  const normalHeader = (showHeaderAndFooter) ? (
    <Header
      handleLoseData={redirect}
      jobs={jobs}
      noJob={pathname.indexOf('/jobs/nojob') === 0}
      push={pushAction}
      user={user}
      wordmark={WORDMARK}
    />
  ) : null

  if (pathname.indexOf('/terms-of-use') !== 0
      && user._isFetched
      && user.isExternal
      && !user.hasAcceptedEula) {
    pushAction('/terms-of-use')
  }

  const header = pathname.indexOf('/error') === 0 ? (
    <HeaderError wordmark={WORDMARK} />
  ) : (
    normalHeader
  )

  return (
    <div>
      {bootstraping ? (
        <Loading />
      ) : (
        <div>
          <div className="header">
            {!isActionHubPage && header}
          </div>
          <div
            className={classnames('container', {
              'container-signin': pathname.indexOf('/signin') === 0,
              'container-error': pathname.indexOf('/error') === 0,
              'container-report': isReportPage,
              'container-actionHub': isActionHubPage,
              'container-banner-1': showHeaderAndFooter && banners.length === 1,
              'container-banner-2': showHeaderAndFooter && banners.length === 2,
              'container-banner-3': showHeaderAndFooter && banners.length === 3,
            })}
          >
            <ScrollToTop>
              <Switch>
                {routes.map((route, i) => (
                  <RouteWithSubRoutes key={i} {...route} />
                ))}
              </Switch>
            </ScrollToTop>
          </div>

          <Snackbar
            action="Dismiss"
            active={notify.active}
            className="notificationBar"
            label={notify.message}
            onClick={hideSnackbar}
            onTimeout={hideSnackbar}
            timeout={notify.timeout || 2800}
            type={notify.alertType}
          />

          <HostedReportModal mode="create" />

          <EditJobNameModal />

          <SupportRequestSentDialog
            isOpen={support._contactFormSentDialogVisible}
            onDismiss={supportContactFormDialogDismissed}
          />
          {showHeaderAndFooter && showFooter && <Footer user={user} />}
        </div>
      )}
    </div>
  )
}

App.propTypes = propTypes

const mapStateToProps = ({
  admin: { bannerAccess: { activeBanners } }, jobs, job, result, app, support,
}) => ({
  jobs,
  job,
  result,
  app,
  support,
  activeBanners,
})

export default withRouter(
  connect(
    mapStateToProps,
    {
      ...appActions,
      ...jobActions,
      ...supportActions,
      push,
    },
  )(App),
)
