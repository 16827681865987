import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Button } from '@deloitte/gel-library'
import { getIndex } from 'utils/arrays'
import Wordmark from 'components/Wordmark'
import HeaderSelect from 'components/HeaderSelect'
import { hot } from 'react-hot-loader/root'
import UserAvatarPopover from 'components/UserAvatarPopover'
import { userPropType } from 'utils/propTypes'
import { Link } from 'react-router-dom'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import Tooltip from '@material-ui/core/Tooltip'
import classNames from 'classnames'
import useJob from 'hooks/useJob'
import { filterBannersByAudience } from 'utils/users'
import InfoBanner from 'components/InfoBanner'
import JobInfoBanner from 'components/JobInfoBanner'
import { IconArrowBackward } from 'icons'
import { Chip } from '@material-ui/core'
import { formatPercent } from 'utils/numbers'
import styles from './ReportHeader.scss'

const propTypes = {
  actionButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  completionProgress: PropTypes.number,
  goBack: PropTypes.func.isRequired,
  isCustom: PropTypes.bool,
  jobId: PropTypes.string.isRequired,
  navigationOptionGroups: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
  })),
  navigationOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onSelect: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  navigationValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['report', 'action']),
  user: userPropType.isRequired,
}

const defaultProps = {
  isCustom: false,
  type: 'report',
  actionButton: null,
  navigationOptionGroups: null,
  navigationOptions: null,
  navigationValue: null,
  completionProgress: 0,
}

function ReportHeader({
  type, user, navigationValue, navigationOptions, navigationOptionGroups,
  actionButton, jobId, goBack, isCustom, completionProgress,
}) {
  const navigationNumber = navigationOptions
    ? getIndex(navigationOptions, x => x.value === navigationValue) + 1
    : null

  const actionSet = navigationOptions?.find(x => x.value === navigationValue)

  const {
    jobName, showExpiryInfoBanner, reachedExecutionLimit, job,
  } = useJob(jobId)

  const { activeBanners } = useSelector(state => state.admin.bannerAccess)
  const banners = activeBanners ? filterBannersByAudience(Object.values(activeBanners), user) : []

  return (
    <>
      {banners.map(b => (
        <InfoBanner
          key={b.name}
          link={b.linkUrl}
          linkText={b.linkText}
          message={b.message}
          mode={b.mode.toLowerCase()}
        />
      ))}

      <div className={classNames(styles.base, (showExpiryInfoBanner) ? styles.expiredJobPadding : null)}>
        <div className={styles.topHeader}>
          <Link className={styles.homeLink} to="/home">
            <Wordmark wordmark="InsightBox" />
          </Link>
          <div>
            {actionButton && (
              <Button
                className={styles.actionButton}
                mode="dark"
                onClick={actionButton.onClick}
              >
                {actionButton.label}
              </Button>
            )}
            <UserAvatarPopover align="right" user={user} />
          </div>
        </div>
        <div className={styles.container}>
          <a className={styles.backButton} onClick={goBack}>
            <IconArrowBackward className={styles.icon} size="16" />
          </a>
          <Tooltip arrow interactive className={styles.tooltip} title={`Back to '${jobName}'`}>
            <div className={styles.jobName}>
              <a className={styles.jobLink} onClick={goBack}>{jobName}</a>
            </div>
          </Tooltip>
          <div className={styles.pipe} />

          {type === 'report'
            ? <InsertChartIcon className={styles.contentIcon} />
            : <AssignmentLateIcon className={styles.contentIcon} />}

          <div className={styles.actionSet}>
            {actionSet ? actionSet.label : ''}
          </div>

          <div className={styles.spacer} />

          {isCustom && (
          <Link className={styles.homeLink} to={!user.isExternal ? '/custom' : '/insights/custom-reports'}>
            <Button
              className={styles.backToCusRep}
              mode="text"
            >
              Back to Reports
            </Button>
          </Link>
          )
          }

          {!showExpiryInfoBanner && type === 'action' && (
            <Chip
              color="primary"
              label={`${formatPercent(completionProgress, 0)} complete`}
              style={{ backgroundColor: '#00A3E0' }}
            />
          )}

          {navigationOptions && (
            <HeaderSelect
              onDark
              endAdornment={`test ${navigationNumber} of ${navigationOptions.length}`}
              onChange={event => navigationOptions.find(x => x.value === event.target.value).onSelect()}
              optionGroups={navigationOptionGroups}
              options={navigationOptions}
              value={navigationOptions.length > 0 ? navigationValue : ''}
            />
          )}
        </div>
        {showExpiryInfoBanner && (
          <JobInfoBanner
            hasExpired={job?.hasExpired}
            reachedExecutionLimit={reachedExecutionLimit}
          />
        )}
      </div>
    </>
  )
}

ReportHeader.propTypes = propTypes
ReportHeader.defaultProps = defaultProps

export default hot(ReportHeader)
