import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import TabPanel from 'components/TabPanel'
import { Tabs } from '@deloitte/gel-library'
import { Divider } from '@material-ui/core'
import styles from './ReviewSection.scss'
import CaseTestingConclusion from '../../../components/CaseTestingConclusion'
import Comments from '../Comments'

const propTypes = {
  actionSetId: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  object: PropTypes.object.isRequired,
  setLabelValue: PropTypes.func.isRequired,
  submitLabels: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
  updatedUser: PropTypes.object,
  validateLabel: PropTypes.func.isRequired,
}

const defaultProps = {
  comments: [],
  disabled: false,
  updatedUser: null,
}

function ReviewSection({
  actionSetId, taskId, comments, disabled, members, object, setLabelValue, submitLabels, updatedUser, validateLabel,
}) {
  const isExternal = useSelector(state => state.app.user.isExternal)
  const [tabValue, setTabValue] = React.useState(0)
  const { allowComments = false } = useSelector(state => state.actionHub.taskLevel)

  const handleChange = (newValue) => {
    setTabValue(newValue)
  }

  const tabLabels = isExternal || !allowComments
    ? ['REVIEW FINDINGS']
    : ['REVIEW FINDINGS',
      <>
        PRACTITIONER COMMENTS
        <span className={styles.circle} style={{ marginLeft: '10px' }}>{(comments?.filter(comment => comment?.commentTypeId === 0) || []).length}</span>
      </>]

  return (
    <div className={styles.base}>
      <>
        <Tabs
          className={styles.tabs}
          onTabChange={value => handleChange(value)}
          tabLabels={tabLabels}
          value={tabValue}
        />
      </>
      <Divider />
      <>
        <TabPanel className={styles.tabPanel} index={0} value={tabValue}>
          <CaseTestingConclusion
            actionSetId={actionSetId}
            comments={comments}
            disabled={disabled}
            members={members}
            object={object}
            setLabelValue={setLabelValue}
            submitLabels={submitLabels}
            taskId={taskId}
            updatedUser={updatedUser}
            validateLabel={validateLabel}
          />
        </TabPanel>
        {!isExternal && allowComments && (
          <TabPanel className={styles.tabPanel} index={1} value={tabValue}>
            <div style={{ padding: '20px 0' }}>
              <Comments
                actionSetId={actionSetId}
                commentType="practitioner"
                disabled={disabled}
                members={members}
                taskId={taskId}
              />
            </div>
          </TabPanel>
        )}
      </>
    </div>
  )
}

ReviewSection.propTypes = propTypes
ReviewSection.defaultProps = defaultProps

export default ReviewSection
