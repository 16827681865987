import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Accordion, DatePicker, TextField } from '@deloitte/gel-library'
import { groupBy, sortByFunction } from 'utils/arrays'
import { isNullOrWhiteSpace } from 'utils/strings'
import { MODAL_BULK_ACTION_UPDATES } from 'constants/forms'
import moment from 'moment/moment'
import tickIcon from 'images/tick-icon.svg'
import styles from './LabelForm.scss'
import SelectRadioSwitchInput from '../../../components/Form/components/SelectRadioSwitchInput'
import Comments from '../../components/Comments'
import LabelField from '../LabelField'
import { updateSignOffName, updateSignOffDate } from '../../redux/actions'
import useTask from '../../hooks/useTask'

const labelFormPropTypes = {
  actionSetId: PropTypes.string.isRequired,
  className: PropTypes.string,
  comments: PropTypes.arrayOf(PropTypes.shape({
    comment: PropTypes.string,
  })).isRequired,
  disabled: PropTypes.bool.isRequired,
  isModified: PropTypes.bool,
  labels: PropTypes.arrayOf(PropTypes.object).isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })).isRequired,
  setLabelValue: PropTypes.func.isRequired,
  taskId: PropTypes.string.isRequired,
  validateLabel: PropTypes.func.isRequired,
  valueForSort: PropTypes.string,
}

const labelFormDefaultProps = {
  className: null,
  isModified: false,
  valueForSort: 'group',
}

function LabelForm({
  actionSetId, taskId, className, disabled, labels, setLabelValue, validateLabel, isModified, valueForSort, comments, members,
}) {
  const dispatch = useDispatch()
  const modals = useSelector(state => state.modals)
  const taskActionId = useSelector(state => state.actionHub.tasks[taskId].taskActionId)
  const { allowClientComments = false, allowSignOff = false } = useSelector(state => state.actionHub.taskLevel)
  const { isCommentRequired, isCommentReceived } = useSelector(state => state.actionHub.tasks[taskId])
  const { signOffName = null, signOffDate = null } = useSelector(state => state.actionHub.tasks?.[taskId] || {})
  const { setTaskActionState } = useTask(actionSetId, taskId)

  const handleSignOffNameChange = useCallback((value) => {
    dispatch(updateSignOffName(taskId, value))
  }, [dispatch, taskId])

  const handleSignOffDateChange = useCallback((value) => {
    dispatch(updateSignOffDate(taskId, moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : null))
  }, [dispatch, taskId])

  const isBulkUpdateModal = MODAL_BULK_ACTION_UPDATES in modals && modals[MODAL_BULK_ACTION_UPDATES].show
  const fields = labels.map(l => ({
    id: l.id,
    group: l.labelGroup,
    name: `${l.displayName}`,
    displayOrder: l.displayOrder,
    isRequired: l.isRequired,
    isComplete: l.isComplete,
    isDisabled: l.isDisabled,
    field: {
      type: l.labelType,
      description: l.description,
      formKey: `${l.id}`,
      options: l.options && l.options.map(x => ({ label: x, value: x })),
    },
    formData: {
      // eslint-disable-next-line no-nested-ternary
      value: isBulkUpdateModal
        ? (!isModified ? '' : l.value)
        : (l.type === 'BIT' ? l.value : l.value || ''),
      valid: l.valid || true,
      error: l.error || null,
    },
    dependencies: l.dependencies,
  })).sort(sortByFunction(x => x.displayOrder))

  // using 'group' as the default value for sort, we can provide other values (displayOrder, name etc..) as prt of the 'fields' attribute with this change.
  const groupedFields = groupBy(fields, valueForSort)
  const [expandedAccordion, setExpandedAccordion] = useState(null)
  const [expandedGroupIndex, setExpandedGroupIndex] = useState(groupedFields.length === 1 ? 0 : -1)
  const [modifiedFields, setModifiedFields] = useState([])

  const handleMainAccordionChange = accordionName => (_, isExpanded) => {
    setExpandedAccordion(isExpanded ? accordionName : null)
  }

  const handleGroupAccordionChange = groupIndex => (_, isExpanded) => {
    setExpandedGroupIndex(isExpanded ? groupIndex : -1)
  }

  const warnIfDirty = useCallback((event) => {
    if (isModified) {
      // eslint-disable-next-line no-param-reassign
      event.returnValue = 'If you leave without saving, you will lose your changes'
    }
  }, [isModified])

  useEffect(() => {
    window.addEventListener('beforeunload', warnIfDirty)
    return () => {
      window.removeEventListener('beforeunload', warnIfDirty)
    }
  }, [warnIfDirty])

  if (groupedFields.length === 1 && isNullOrWhiteSpace(groupedFields[0].key)) {
    return (
      <div className={classNames(styles.base, className)}>
        {fields.map(f => (
          <LabelField
            disabled={disabled}
            field={f}
            isBulkUpdateModal={isBulkUpdateModal}
            key={f.id}
            modifiedFields={modifiedFields}
            setModifiedFields={setModifiedFields}
            setValue={setLabelValue}
            validateLabel={validateLabel}
          />
        ))}
      </div>
    )
  }

  return (
    <div className={classNames(styles.base, className)}>
      {groupedFields.map(({ key: groupName, values: groupLabels }, index) => {
        const requiredLabels = groupLabels.filter(l => l.isRequired && !l.isDisabled)
        const completeLabels = isBulkUpdateModal ? requiredLabels.filter(l => l.isComplete && modifiedFields.includes(l.id)) : requiredLabels.filter(l => l.isComplete)
        const completeStatus = requiredLabels.length > 0
          ? `${completeLabels.length} of ${requiredLabels.length} required`
          : ''

        return (
          <Accordion
            className={styles.accordion}
            detailsClassName={styles.formValues}
            expanded={expandedGroupIndex === index}
            heading={(
              <p className={styles.groupHeading}>
                {groupName}
                <span className={styles.itemCount}>{`${groupLabels.length} items`}</span>
                <span className={styles.spacer} />
                <span className={styles.complete}>{completeStatus}</span>
              </p>
              )}
            key={groupName}
            onChange={handleGroupAccordionChange(index)}
          >
            {groupLabels.map(f => (
              <LabelField
                disabled={disabled}
                field={f}
                isBulkUpdateModal={isBulkUpdateModal}
                key={f.id}
                modifiedFields={modifiedFields}
                setModifiedFields={setModifiedFields}
                setValue={setLabelValue}
                validateLabel={validateLabel}
              />
            ))}
          </Accordion>
        )
      })}

      {allowClientComments && (
        <Accordion
          className={styles.accordion}
          detailsClassName={styles.formValues}
          expanded={expandedAccordion === 'clientComments'}
          heading={(
            <p>
              <span className={classNames(styles.circle, { [styles.circleExpanded]: expandedAccordion === 'clientComments' })}>
                {(comments.filter(comment => comment.commentTypeId === 1)).length > 0 ? <img alt="Tick Icon" src={tickIcon} /> : '2'}
              </span>
              Client comments
              <span className={styles.circle} style={{ marginLeft: '10px' }}>{(comments.filter(comment => comment.commentTypeId === 1)).length}</span>
            </p>
          )}
          onChange={handleMainAccordionChange('clientComments')}
        >
          <SelectRadioSwitchInput
            label="Does this action require client comments"
            onChange={(_, value) => {
              if (value) {
                const hasClientComments = comments.filter(comment => comment.commentTypeId === 1).length > 0
                setTaskActionState(taskActionId, hasClientComments ? 5 : 4)
              } else {
                setTaskActionState(taskActionId, 0)
              }
            }}
            options={[{ label: 'No', value: false }, { label: 'Yes', value: true }]}
            value={isCommentRequired || isCommentReceived}
          />
          {(isCommentRequired || isCommentReceived) && (
            <div style={{ marginTop: '20px' }}>
              <div style={{ marginBottom: '10px' }}>Send comments to client</div>
              <Comments
                actionSetId={actionSetId}
                commentType="client"
                disabled={disabled}
                members={members}
                taskId={taskId}
              />
            </div>
          )}
        </Accordion>
      )}

      {allowSignOff && (
        <Accordion
          className={styles.accordion}
          detailsClassName={styles.formValues}
          expanded={expandedAccordion === 'signOff'}
          heading={(
            <p>
              <span className={classNames(styles.circle, { [styles.circleExpanded]: expandedAccordion === 'signOff' })}>
                {signOffName && signOffDate ? <img alt="Tick Icon" src={tickIcon} /> : '3'}
              </span>
              Sign off
            </p>
          )}
          onChange={handleMainAccordionChange('signOff')}
        >
          <div style={{ marginBottom: '10px' }}>
            <TextField
              defaultValue={signOffName ?? null}
              label="Name"
              onChange={(_, value) => { handleSignOffNameChange(value) }}
            />
          </div>
          <div style={{ marginBottom: '50px' }}>
            <DatePicker
              error=""
              fullWidth={false}
              helperText=""
              label="Completion date"
              maxDate={new Date()}
              onChange={(date) => { handleSignOffDateChange(date) }}
              value={signOffDate ? moment(signOffDate) : null}
            />
          </div>
        </Accordion>
      )}
    </div>
  )
}

LabelForm.propTypes = labelFormPropTypes
LabelForm.defaultProps = labelFormDefaultProps

export default LabelForm
