import React, {
  useCallback, useEffect, useRef, useState, useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { formatDateTimeFromStringLong } from 'utils/dates'
import { sortByFunction } from 'utils/arrays'
import { Button } from '@deloitte/gel-library'
import TextArea from 'components/TextArea'
import attachFileIcon from 'images/attach-file-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import styles from './Comments.scss'
import { fetchSupportingData, updateSupportingFiles, updateSupportingFilesStatus } from '../../../actions/job'
import { notify } from '../../../actions/app'
import { downloadAttachedFile, removeSupportingFile, uploadSupportingFiles } from '../../../actions/file'
import fileIcon from '../../../images/file-icon-green.svg'
import binIcon from '../../../images/binIcon.svg'
import Spinner from '../../../components/Spinner'
import useTask from '../../hooks/useTask'

const Comments = ({
  className, actionSetId, taskId, commentType, disabled, members,
}) => {
  const [commentText, setCommentText] = useState('')
  const chatViewRef = useRef(null)
  const dispatch = useDispatch()
  const fileInputRef = useRef(null)

  const { submitTaskComment } = useTask(actionSetId, taskId)
  const {
    job: {
      jobId, supportingFileStatus, supportingFiles, _isUploadingFiles,
    },
    app: { user: { isExternal } },
  } = useSelector(state => state)

  const { comments } = useSelector(state => state.actionHub.tasks[taskId])

  useEffect(() => {
    if (chatViewRef.current) {
      chatViewRef.current.scrollTop = chatViewRef.current.scrollHeight
    }
  }, [comments])

  useEffect(() => {
    dispatch(fetchSupportingData(jobId, isExternal, actionSetId, taskId, commentType === 'client' ? '0' : '1'))
  }, [dispatch, jobId, isExternal, actionSetId, taskId, commentType])

  const newSupportingFileStatus = useMemo(() => supportingFileStatus.filter(
    file => !comments.some(comment => comment.fileNames?.includes(file.name)),
  ), [supportingFileStatus, comments])

  const sortedComments = useMemo(() => {
    const isCommentTypeFiltered = (comment) => {
      if (commentType === 'practitioner') { return comment.commentTypeId !== 1 }
      if (commentType === 'client') { return comment.commentTypeId !== 0 }
      return true
    }

    return comments
      .filter(isCommentTypeFiltered)
      .sort(sortByFunction(x => x.postedAt, true))
  }, [comments, commentType])

  const handleFileChange = useCallback((event) => {
    const filesArr = Array.from(event.target.files)
    const duplicateFiles = filesArr.filter(file => supportingFiles.some(f => f.name === file.name))

    if (duplicateFiles.length > 0) {
      dispatch(notify('Files with duplicate names cannot be uploaded.'))
    } else {
      const newFilesWithStatus = filesArr.map(file => ({
        name: file.name,
        size: file.size,
        status: 'pending',
        tablename: `${actionSetId}/${taskId}/${commentType === 'client' ? '0' : '1'}`,
        date: new Date(),
      }))
      dispatch(updateSupportingFiles([...supportingFiles, ...filesArr]))
      dispatch(updateSupportingFilesStatus([...supportingFileStatus, ...newFilesWithStatus]))
    }
  }, [supportingFiles, dispatch, supportingFileStatus, actionSetId, taskId, commentType])

  const handleFileDeletion = useCallback((fileToDelete) => {
    const isFileComplete = supportingFileStatus.find(file => file.name === fileToDelete.name)?.status === 'complete'
    const removeFilePromise = isFileComplete
      ? dispatch(removeSupportingFile({ name: fileToDelete.name, tablename: fileToDelete.tablename }, jobId, isExternal))
      : Promise.resolve()

    removeFilePromise
      .then(() => {
        const newSupportFiles = supportingFiles.filter(file => file.name !== fileToDelete.name)
        const newFileStatus = supportingFileStatus.filter(file => file.name !== fileToDelete.name)
        dispatch(updateSupportingFiles(newSupportFiles))
        dispatch(updateSupportingFilesStatus(newFileStatus))
      })
      .catch(() => {
        dispatch(notify('Failed to remove file.'))
      })
  }, [dispatch, supportingFiles, supportingFileStatus, jobId, isExternal])

  const handleFileDownload = useCallback((file) => {
    const fileDto = {
      name: file.name,
      tablename: file.tablename,
    }
    dispatch(downloadAttachedFile(fileDto, jobId, isExternal))
  }, [dispatch, jobId, isExternal])

  const handlePostClick = useCallback(() => {
    const PendingFilesStatus = supportingFileStatus.filter(f => f.status !== 'complete')
    const formsData = PendingFilesStatus
      .map((pendingFileStatus) => {
        const pendingFile = supportingFiles.find(f => f.name === pendingFileStatus.name)
        if (!pendingFile) { return null }
        const formData = new FormData()
        formData.append('file', pendingFile, pendingFile.name)
        formData.append('tableCode', pendingFileStatus.tablename)
        formData.append('jobId', jobId)
        formData.append('qqfilename', pendingFile.name)
        formData.append('locationRef', 'supporting_files')
        return formData
      })
      .filter(Boolean)
    if (formsData.length > 0) {
      dispatch(uploadSupportingFiles(formsData, isExternal))
        .then(() => {
          const updatedFileStatus = supportingFileStatus.map(file => (PendingFilesStatus.some(f => f.name === file.name) ? { ...file, status: 'complete' } : file))
          dispatch(updateSupportingFilesStatus(updatedFileStatus))
        })
        .catch((error) => {
          console.error('Error uploading files:', error)
          dispatch(notify('Failed to upload files. Please try again.'))
        })
    }

    const pendingFilesNames = PendingFilesStatus.map(f => f.name)
    submitTaskComment(commentText, pendingFilesNames, commentType)
    setCommentText('')
  }, [supportingFileStatus, submitTaskComment, commentText, commentType, supportingFiles, jobId, dispatch, isExternal])

  const renderComment = useCallback(({
    id, message, userId, postedAt, fileNames,
  }) => {
    const user = members.find(member => member.id === userId)?.userDetails
    const filteredFiles = supportingFileStatus?.filter(file => fileNames?.includes(file.name))

    return (
      <div className={styles.previousComment} key={id}>
        <div className={styles.commentContent}>
          <div className={styles.commentHeader}>
            <div>{formatDateTimeFromStringLong(postedAt)}</div>
            <div>&nbsp;&nbsp;|&nbsp;&nbsp;</div>
            <div>{`${user.firstName} ${user.surname} (${user.email})`}</div>
          </div>
          <div className={styles.comment}>{message}</div>
          <div className={styles.attachments}>
            {filteredFiles?.map(file => (
              <div className={styles.attachment} key={file.name}>
                <img alt="File Icon" className={styles.fileIcon} src={fileIcon} />
                <span
                  className={styles.fileName}
                  onClick={() => handleFileDownload(file)}
                >
                  {file.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }, [handleFileDownload, members, supportingFileStatus])

  return (
    <div className={className}>
      {!disabled && (
        <div>
          <TextArea
            className={styles.textArea}
            disabled={disabled}
            height="120px"
            onChange={setCommentText}
            placeholder="Type your comment here..."
            value={commentText}
          />
          <div className={styles.actionBar}>
            <div className={styles.fileList}>
              {newSupportingFileStatus?.map(file => (
                <div className={styles.fileItem} key={file.name}>
                  <img alt="File Icon" className={styles.fileIcon} src={fileIcon} />
                  <span
                    className={styles.fileName}
                    onClick={() => handleFileDownload(file)}
                  >
                    {file.name}
                  </span>
                  <button
                    aria-label={`Delete ${file.name}`}
                    className={styles.deleteButton}
                    onClick={() => handleFileDeletion(file)}
                    type="button"
                  >
                    <img alt="Delete" className={styles.deleteIcon} src={binIcon} />
                  </button>
                </div>
              ))}
            </div>
            <div className={styles.controls}>
              <button
                aria-label="Attach file"
                className={styles.attachButton}
                onClick={() => fileInputRef.current?.click()}
                type="button"
              >
                <img alt="Attach file Icon" className={styles.attachIcon} src={attachFileIcon} />
              </button>
              <input
                id="attach-file"
                onChange={handleFileChange}
                onClick={(e) => {
                  e.target.value = ''
                }}
                ref={fileInputRef}
                style={{ display: 'none' }}
                type="file"
              />
              <Button
                disabled={
                  (commentText.trim().length === 0 || disabled)
                  && (supportingFiles.length === 0 || !supportingFileStatus.some(f => f.status !== 'complete'))
                }
                mode="secondary"
                onClick={handlePostClick}
              >
                POST
                {_isUploadingFiles && <Spinner className={styles.spinner} />}
              </Button>
            </div>
          </div>
        </div>
      )}
      {sortedComments.length > 0 && <div className={styles.separator} />}
      <div className={styles.previousComments} ref={chatViewRef}>
        {sortedComments.map(renderComment)}
      </div>
    </div>
  )
}

Comments.propTypes = {
  actionSetId: PropTypes.string.isRequired,
  className: PropTypes.string,
  commentType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  members: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    userDetails: PropTypes.shape({
      email: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      surname: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
  taskId: PropTypes.string.isRequired,
}

Comments.defaultProps = {
  className: null,
  disabled: false,
}

export default React.memo(Comments)
