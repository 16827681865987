import React, { useRef, useState } from 'react'
import PropTypes, { object } from 'prop-types'
import classNames from 'classnames'
import { DropdownMenu } from '@deloitte/gel-library'
import { userPropType } from 'utils/propTypes'
import User from 'components/User'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Popover } from '@material-ui/core'
import { useSelector } from 'react-redux'
import styles from './ActionBar.scss'
import TickmarkGenerator from '../TickmarkGenerator'

function ActionBar({
  actionStates,
  deSelectAll,
  isVisible,
  selectedTaskIds,
  members,
  onHandleOpenModal,
  setTasksAssigned,
  setTasksActionState,
  actionSetId,
}) {
  const people = members.map(m => ({
    key: m.id,
    label: <User user={m} />,
    onClick: () => {
      setTasksAssigned(selectedTaskIds, m.id)
      deSelectAll()
    },
  }))

  const actions = actionStates.map(as => ({
    key: `${as.id}`,
    label: as.id === 0 ? 'No Action' : as.description,
    onClick: () => {
      setTasksActionState(selectedTaskIds, as.id)
      deSelectAll()
    },
  }))

  const disabled = selectedTaskIds.length === 0

  const buttonRef = useRef(null)
  const { allowTags } = useSelector(state => state.actionHub)
  const { allowClientComments = false } = useSelector(state => state.actionHub.taskLevel)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = () => {
    setAnchorEl(buttonRef.current)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleApply = () => {
    setAnchorEl(null)
    deSelectAll()
  }

  const handleReleaseToClient = () => {
    setTasksActionState(selectedTaskIds, 4)
    deSelectAll()
  }

  return (
    <div className={classNames(styles.base,
      disabled ? styles.disabled : null,
      isVisible ? null : styles.hidden)}
    >
      <div className={styles.selectedCount}>
        {selectedTaskIds.length > 1 && `${selectedTaskIds.length} items selected`}
        {selectedTaskIds.length === 1 && `${selectedTaskIds.length} item selected`}
      </div>
      <div className={styles.spacer} />
      <span className={styles.separator} />
      <div className={styles.actionButton} onClick={() => onHandleOpenModal(true)}>
        + UPDATE ROWS
      </div>
      <span className={styles.separator} />
      <div>
        <DropdownMenu displayText="ASSIGN ACTION" options={actions} />
      </div>
      <span className={styles.separator} />
      <div>
        <DropdownMenu displayText="ASSIGN USER" options={people} />
      </div>
      {allowTags
        && (
        <div className={classNames(styles.manageTick, anchorEl !== null ? styles.active : null)}>
          <div onClick={handleClick}>
            MANAGE TICKMARK
          </div>
          <ArrowDropDownIcon
            onClick={handleClick}
            ref={buttonRef}
          />
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={handleClose}
            open={open}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <TickmarkGenerator
              actionSetId={actionSetId}
              onApply={handleApply}
              onClose={handleClose}
              selectedTaskIds={selectedTaskIds}
            />
          </Popover>
        </div>
        )}
      <span className={styles.separator} />
      {allowClientComments && (
        <>
          <div className={styles.actionButton} onClick={handleReleaseToClient}>
            RELEASE TO CLIENT
          </div>
          <span className={styles.separator} />
        </>
      )}
      <div className={styles.actionButton} onClick={deSelectAll}>
        DESELECT ALL
      </div>
    </div>
  )
}

ActionBar.propTypes = {
  actionSetId: PropTypes.string.isRequired,
  actionStates: PropTypes.arrayOf(object).isRequired,
  deSelectAll: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  members: PropTypes.arrayOf(userPropType).isRequired,
  onHandleOpenModal: PropTypes.func.isRequired,
  selectedTaskIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setTasksActionState: PropTypes.func.isRequired,
  setTasksAssigned: PropTypes.func.isRequired,
}

ActionBar.defaultProps = {
  isVisible: false,
}

export default ActionBar

