import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import { fetchJobMembers, fetchActions, fetchJobDetails } from 'actions/job'
import { jobGetMemberGroups, roles, userHasJobRole } from 'utils/business/jobs'
import { jobInitialState } from 'reducers/job'
import { fetchClientActions, fetchClientJob } from 'actions/client'
import { canAccessActionHub } from 'utils/permissions'
import { userPropType } from 'utils/propTypes'

export default function useJob(jobId, isExternalUserParam = false) {
  const dispatch = useDispatch()
  const user = useSelector(state => state.app.user)
  const isExternalUser = user.isExternal || isExternalUserParam

  const getBaseJobState = (state) => {
    return isExternalUser
      ? state.client.jobs[jobId] || jobInitialState
      : state.job
  }
  const job = useSelector(state => getBaseJobState(state))

  const {
    actions,
    analyses,
    engagement,
    members,
    name: jobName,
    clientName,
    packageName,
    packageId,
    _fetchingJobDetailsId,
    _fetchedJobDetailsId,
  } = job

  const reachedExecutionLimit = job?.hasExecutionLimit && job?.noOfRuns >= job?.executionLimit
  const showExpiryInfoBanner = job?.hasExpired || reachedExecutionLimit

  const numMembers = members?.length

  const { deloitteTeam, externalTeam } = jobGetMemberGroups(members)

  const clientJobLoaded = job && job.id && job.id.length > 0

  useEffect(() => {
    if (isExternalUser && !clientJobLoaded && jobId) {
      dispatch(fetchClientJob(jobId))
    }
  }, [dispatch, jobId, isExternalUser, clientJobLoaded])

  // Loads job info & members on job
  useEffect(() => {
    if (jobId && !isExternalUser && _fetchingJobDetailsId !== jobId && _fetchedJobDetailsId !== jobId) {
      dispatch(fetchJobDetails({ jobId }))
    }
  }, [_fetchedJobDetailsId, _fetchingJobDetailsId, dispatch, isExternalUser, jobId])

  useEffect(() => {
    if (jobId && numMembers === 0 && !isExternalUser && !job._isFetchingJobMembers) {
      dispatch(fetchJobMembers(jobId))
    }
  }, [dispatch, jobId, isExternalUser, numMembers, job._isFetchingJobMembers])

  const canAccessJobActions = user && canAccessActionHub(user)
    && (userHasJobRole(user, job, roles.JobExternalActionReader) || userHasJobRole(user, job, roles.JobExternalActionEditor))

  const isAssignedActionsEditor = user && canAccessActionHub(user) && userHasJobRole(user, job, roles.ExternalAssignedActionsEditor)

  // Loads actions for job
  // Todo: find a way to avoid this running multiple times when useJob is called twice close together
  useEffect(() => {
    if (canAccessJobActions && jobId && (!actions) && !job._isFetchingActions && !job._isFetchedActions) {
      if (isExternalUser) {
        dispatch(fetchClientActions(jobId))
      } else {
        dispatch(fetchActions({ jobId }))
      }
    }
  }, [dispatch, canAccessJobActions, jobId, isExternalUser, actions, job._isFetchingActions, job._isFetchedActions])

  const goToInternalJob = (tab = 'details') => {
    dispatch(push(`/job/${jobId}/${tab}`))
  }

  const goToClientJob = () => {
    dispatch(push(`/insights/${packageId}/${jobId}/reports`))
  }

  const goToInternalReport = (analysisId) => {
    dispatch(push(`/report/${jobId}/${analysisId}`))
  }

  const goToClientReport = (analysisId) => {
    dispatch(push(`/insights/${packageId}/report/${jobId}/${analysisId}`))
  }

  const userJobMember = job && job.members && user && user.id
    ? job.members.find(m => m.id === user.id)
    : null

  const userHasJobPermission = (permission) => {
    if (!job || !job.members || !userJobMember || !userJobMember.role || !userJobMember.role.permissions) {
      return false
    }
    return userJobMember.role.permissions.map(p => p.name).includes(permission)
  }
  const goToJob = (tab = 'details') => {
    const userDoesntHaveRole = (!userHasJobRole(user, job, roles.JobExternalEditor) || !userHasJobRole(user, job, roles.JobExternalActionEditor))
    if (isExternalUser && userDoesntHaveRole) {
      goToClientJob()
    } else {
      goToInternalJob(tab)
    }
  }

  const goToReport = (analysisId) => {
    if (isExternalUser) {
      goToClientReport(analysisId)
    } else {
      goToInternalReport(analysisId)
    }
  }

  const getBreadcrumbs = ({ isExternal, extraCrumbs = [] }) => {
    const externalBreadcrumbs = isExternal
      ? [{
        key: 'package',
        label: packageName,
        onClick: () => dispatch(push(`/insights/${packageId}`)),
      }]
      : []
    const internalBreadcrumbs = !isExternal && engagement && engagement.name !== 'Unallocated'
      ? [{
        key: 'engagement',
        label: engagement && engagement.name,
      }]
      : []

    return [
      ...externalBreadcrumbs,
      ...internalBreadcrumbs,
      {
        key: 'job',
        label: jobName,
        onClick: goToJob,
      },
      ...extraCrumbs,
    ]
  }

  const getNavigationOptions = (actionSet, isCurrent, goToOtherAction) => {
    // Header config
    const currentActions = (actions && actions.currentActions) || []
    const reportActionButton = actionSet.analysisName
      ? {
        label: 'View report',
        onClick: () => goToReport(actionSet.analysisName),
      } : null
    const analysisOptions = analyses.filter(fa => fa.hasData).map((a) => {
      const action = currentActions.find(x => x.actionSet.analysisName === a.id)
      return {
        value: action ? action.actionSet.id : a.id,
        label: a.name,
        onSelect: action
          ? () => goToOtherAction(action.actionSet.id)
          : () => goToReport(a.id),
      }
    })
    const packageOptions = (actions && actions.currentActions
      .filter(a => a.actionSet.scope === 'package')
      .map(a => ({
        value: a.actionSet.id,
        label: a.displayName,
        onSelect: () => goToOtherAction(a.actionSet.id),
      }))) || []

    const options = [
      ...packageOptions,
      ...analysisOptions,
    ]

    const optionGroups = isCurrent && packageOptions.length > 0 && analysisOptions.length > 0
      ? [
        { name: 'Solution', values: packageOptions.map(x => x.value) },
        { name: 'Analyses', values: analysisOptions.map(x => x.value) },
      ]
      : null

    return {
      options, optionGroups, packageOptions, analysisOptions, reportActionButton,
    }
  }

  return {
    job,
    analyses,
    deloitteTeam,
    externalTeam,
    jobName,
    clientName,
    packageName,
    packageId,
    actions,
    engagement,
    canAccessJobActions,
    isAssignedActionsEditor,
    userJobMember,
    goToJob,
    goToReport,
    getBreadcrumbs,
    getNavigationOptions,
    userHasJobPermission,
    reachedExecutionLimit,
    showExpiryInfoBanner,
  }
}

export const useJobPropTypes = PropTypes.shape({
  deloitteTeam: PropTypes.arrayOf(userPropType),
  externalTeam: PropTypes.arrayOf(userPropType),
  userHasJobPermission: PropTypes.func,
})
