import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
  getDataDetails, postDataComment, putLabels,
} from 'actionHub/utils/actionHubApi'
import {
  actionHubFetchingDataDetail, actionHubFetchedDataDetail, actionHubUpdateDataLabelValue, validateDataLabel, submittingDataLabels, submittedDataLabels, submittingDataComment, submittedDataComment,
} from 'actionHub/redux/actions'
import useApp from 'hooks/useApp'

export default function useData(actionSetId, taskId, dataId) {
  const dispatch = useDispatch()
  const { showNotification } = useApp()

  const data = useSelector(state => state.actionHub.data[dataId]) || null
  const needsLoading = dataId != null && (data === null || !data._isFetched)
  const {
    displayColumns: columns, idColumns,
  } = useSelector(state => state.actionHub.dataLevel)

  useEffect(() => {
    if (needsLoading) {
      dispatch(actionHubFetchingDataDetail(actionSetId, taskId, dataId))
      getDataDetails(actionSetId, taskId, dataId)
        .then(({ data: responseData }) => {
          dispatch(actionHubFetchedDataDetail(actionSetId, taskId, dataId, responseData))
        })
        .catch((e) => {
          showNotification('Could not load data, please refresh the page and contact support if issues persist.', e)
        })
    }
  }, [dispatch, actionSetId, taskId, dataId, needsLoading, showNotification])

  const setLabelValue = (labelId, value) => {
    dispatch(actionHubUpdateDataLabelValue(actionSetId, dataId, labelId, value))
  }

  const validateLabel = (labelId) => {
    dispatch(validateDataLabel(actionSetId, dataId, labelId))
  }

  const submitDataLabels = () => {
    dispatch(submittingDataLabels(actionSetId, taskId, dataId))
    putLabels(actionSetId, 1, dataId, data.labels)
      .then(() => {
        dispatch(submittedDataLabels(actionSetId, taskId, dataId, data.labels))
        showNotification('Review findings saved')
      })
      .catch((e) => {
        showNotification('Could not save changes, please refresh the page and contact support if issues persist.', e)
      })
  }

  const submitDataComment = (comment) => {
    dispatch(submittingDataComment(actionSetId, taskId, dataId))
    postDataComment(actionSetId, dataId, { comment })
      .then(({ data: responseData }) => {
        dispatch(submittedDataComment(actionSetId, taskId, dataId, responseData))
      })
      .catch((e) => {
        showNotification('Could not save comment, please refresh the page and contact support if issues persist.', e)
      })
  }

  return {
    data,
    columns: columns.map(x => ({ ...x, idColumn: idColumns.includes(x.name) })),
    setLabelValue,
    validateLabel,
    submitDataLabels,
    submitDataComment,
  }
}
