import { getConfig } from 'utils/config'
import { stringify } from 'query-string'
import {
  deleteRequest,
  getRequest, getRequestBinaryFile, patchRequest, postRequest, putRequest,
} from '../api'

const baseJobUrl = isExternal => `${isExternal ? '/client' : ''}/job`
const baseJobIdUrl = (jobId, isExternal, suffix) => `${baseJobUrl(isExternal)}/${jobId}/${suffix}`

const jobIdGetRequest = (jobId, suffix, isExternal) => getRequest(baseJobIdUrl(jobId, isExternal, suffix))
const jobIdPostRequest = (jobId, suffix, data, isExternal) => postRequest(baseJobIdUrl(jobId, isExternal, suffix), data)

export const importPrevAction = (actionSetId, actionIdToImport) => postRequest(`/action/${actionSetId}/level/0/labels?actionIdToImport=${actionIdToImport}`)

export const getSingleJob = (jobId, isExternal = false) => jobIdGetRequest(jobId, '', isExternal)
export const postJob = (jobData, isExternal = false) => postRequest(baseJobUrl(isExternal), jobData)
export const putDataPrepRequest = (jobId, description) => putRequest(`/job/${jobId}/dataPreparationRequest`, { description })
export const postAnalyses = (jobId, data, isExternal = false) => jobIdPostRequest(jobId, 'analyses', data, isExternal)
export const postBenchmarkFlag = (jobId, benchmarkAllowed) => postRequest(
  `/job/${jobId}/benchmark`, { benchmarkAllowed },
)

export const getTargetSchemas = packageName => getRequest(
  `/package/${packageName}/schemas`,
)

export const postLaunchWorkbench = jobId => postRequest(
  `/workbench/${jobId}`,
)

export const sendDataPrepEmail = (jobId, isExternal = false) => putRequest(`${isExternal ? '/client' : ''}/job/${jobId}/notify`)

export const deleteDataPrepRequest = jobId => deleteRequest(
  `/job/${jobId}/dataPreparationRequest`,
)

export const postFileGroup = (dataPreparationRequestId, fileGroupData) => postRequest(`/fileGroup/${dataPreparationRequestId}`, fileGroupData)
export const updateFileGroup = (fileGroupId, fileGroupUpdatedData) => putRequest(`/fileGroup/${fileGroupId}`, fileGroupUpdatedData)
export const getAllFileGroups = dataPreparationRequestId => getRequest(`/fileGroup/${dataPreparationRequestId}`)
export const getAllSupportingFiles = (jobId, isExternal) => getRequest(`${isExternal ? '/client' : ''}/file/supportingfile/${jobId}`)

export const getJobMembers = (jobId, isExternal = false) => jobIdGetRequest(jobId, 'members', isExternal)
export const postJobMembers = (jobId, data, isExternal = false) => jobIdPostRequest(jobId, 'members', data, isExternal)

export const getJobTestParameters = (jobId, isExternal = false) => jobIdGetRequest(jobId, 'parameters/test', isExternal)

export const postParameters = (jobId, data, isExternal = false) => jobIdPostRequest(jobId, 'parameters/test', data, isExternal)

export const getJobParameters = (jobId, isExternal = false) => jobIdGetRequest(jobId, 'parameters', isExternal)
export const postJobParameters = (jobId, data, isExternal = false) => jobIdPostRequest(jobId, 'parameters', data, isExternal)
export const postJobPackageParameters = (jobId, data, isExternal = false) => jobIdPostRequest(jobId, 'parameters/upsert', data, isExternal)

export const getJobActions = jobId => getRequest(`/action/job/${jobId}`)
export const getJobCurrentActions = jobId => getRequest(`/action/job/${jobId}/current`)

export const getJobReports = (jobId, isExternal = false) => jobIdGetRequest(jobId, 'reports', isExternal)

export const getJobDownloads = (jobId, isExternal = false) => getRequest(`${isExternal ? '/client' : ''}/job/${jobId}/downloads`)
export const getJobDownloadStatus = (jobId, downloadName, isExternal = false) => getRequest(`${isExternal ? '/client' : ''}/job/${jobId}/downloads/${downloadName}/status`)
export const putStartDownload = (jobId, downloadName, isExternal = false) => putRequest(`${isExternal ? '/client' : ''}/job/${jobId}/downloads/${downloadName}`)

export const putUpdateDownloadStatus = (jobId, downloadName, isExternal = false) => putRequest(`${isExternal ? '/client' : ''}/job/${jobId}/downloads/${downloadName}/reset`)

export const downloadJobDownload = (jobId, downloadName, isExternal = false) => {
  const { API_BASE } = getConfig()
  window.open(`${API_BASE}${isExternal ? '/client' : ''}/job/${jobId}/downloads/${downloadName}`)
}

export const getJobRequiredTables = ({
  jobId, includeColumns = false, recipe = '', isExternal = false,
}) => {
  const queryString = stringify({ recipe, includeColumns }, { skipNull: true })
  return jobIdGetRequest(jobId, `data?${queryString}`, isExternal)
}

export const getJobDataRecipes = (jobId, isExternal = false) => getRequest(`${isExternal ? '/client' : ''}/job/${jobId}/recipes`)

export const getJobSampleDataJson = ({ jobId, recipe = '', isExternal = false }) => {
  const queryString = stringify({ format: 'json', recipe })
  return getRequest(`${isExternal ? '/client' : ''}/job/${jobId}/sampledata?${queryString}`)
}

export const getJobSampleDataZip = ({ jobId, recipe = '', isExternal = false }) => {
  const queryString = stringify({ format: 'zip', recipe })
  return getRequestBinaryFile(`${isExternal ? '/client' : ''}/job/${jobId}/sampledata?${queryString}`)
}

export const patchUpdateJob = (jobId, job) => patchRequest(`/job/${jobId}`, job)
export const patchUpdateJobName = (jobId, name, isExternal = false) => patchRequest(baseJobIdUrl(jobId, isExternal, `name/${name}`))
export const patchReActivateJob = (jobId, executionLimit, expiryDate, isExternal = false) => patchRequest(baseJobIdUrl(jobId, isExternal, `reActivate/${executionLimit}/${expiryDate}`))
export const getValidationSummary = (jobId, isExternal = false) => jobIdGetRequest(jobId, 'validation', isExternal)
export const getValidationResults = (jobId, validationName, page, isExternal = false) => jobIdGetRequest(jobId, `validation/${validationName}?page=${page}`, isExternal)
export const getDataValidationResults = (jobId, fileId, page, isExternal = false) => jobIdGetRequest(jobId, `datavalidation/${fileId}?page=${page}`, isExternal)

export const postAddUserToJob = (jobId, userId, isNewUser, role = null) => {
  const query = new URLSearchParams({
    ...(isNewUser ? { sendNotification: false } : {}),
    ...(role ? { role } : {}),
  })
  return postRequest(`/job/${jobId}/user/${userId}?${query.toString()}`)
}

export const putStartExecution = (jobId, isExternal = false) => putRequest(baseJobIdUrl(jobId, isExternal, 'execute'))

export const getJobAssignableExternalRoles = jobId => getRequest(`/user/roles/job/${jobId}/external`)
