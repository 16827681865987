import React, { useState, useEffect, createRef } from 'react'
import PropTypes from 'prop-types'
import { fetchActions } from 'actions/job'
import { userPropType } from 'utils/propTypes'
import LoadingBox from 'components/LoadingBox'
import { fetchClientActions } from 'actions/client'
import { roles } from 'utils/business/jobs'
import { jobIsProcessing } from 'utils/business/workflow'
import { isTacTClient } from 'utils/permissions'
import { useDispatch } from 'react-redux'
import ActionGroup from 'components/ActionGroup'
import Container from 'components/layout/Grid/Container'
import classnames from 'classnames'
import CurrentActions from './CurrentActions'
import styles from './Actions.scss'

const propTypes = {
  canAccessActionHub: PropTypes.bool.isRequired,
  job: PropTypes.shape({
    _fetchingActionsError: PropTypes.string,
    _isCreatingActions: PropTypes.bool,
    _isFetchedActions: PropTypes.bool,
    _isFetchingActions: PropTypes.bool,
    actions: PropTypes.shape({
      _hasFetchedAllActions: PropTypes.bool,
      currentActions: PropTypes.arrayOf(PropTypes.object),
      potentialActions: PropTypes.arrayOf(PropTypes.object),
      previousActions: PropTypes.arrayOf(PropTypes.object),
    }),
    hasActions: PropTypes.bool,
    hasExpired: PropTypes.bool,
    jobId: PropTypes.string,
    lastExecution: PropTypes.object,
    member: PropTypes.object,
    members: PropTypes.arrayOf(userPropType),
    packageId: PropTypes.string,
  }).isRequired,
  uploaders: PropTypes.shape({
    uploaders: PropTypes.object,
  }).isRequired,
  user: PropTypes.shape({
    _isFetched: PropTypes.bool,
    isExternal: PropTypes.bool,
  }).isRequired,
  validations: PropTypes.shape({
    validations: PropTypes.object,
  }).isRequired,
}

function Actions({
  canAccessActionHub,
  user,
  job,
  validations: { validations },
}) {
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState(0)

  const { isExternal, _isFetched } = user
  const {
    jobId,
    actions,
    _isFetchedActions,
    _isFetchingActions,
    _fetchingActionsError,
    member,
    hasActions,
    hasExpired,
    packageId,
  } = job
  const userJobRole = member?.role?.name

  useEffect(() => {
    if (jobId && _isFetched) {
      if (isExternal && userJobRole !== roles.JobExternalEditor) {
        dispatch(fetchClientActions(jobId))
      } else {
        dispatch(fetchActions({ jobId }))
      }
    }
  }, [dispatch, jobId, isExternal, _isFetched, userJobRole])

  const { previousActions } = (actions || {})

  const showPreviousActions = previousActions && previousActions.length > 0 && !isExternal

  const canRenderActions = _isFetchedActions && !_fetchingActionsError && actions && actions._hasFetchedAllActions

  const actionTabs = ['Active actions', 'Archived actions']
  const actionsRef = createRef()

  return (
    <Container className={styles.base}>
      <div className={styles.tabs}>
        {actionTabs.map((tab, idx) => (
          <div
            className={classnames(styles.navItem, selectedTab === idx ? styles.isActive : '')}
            key={tab}
            onClick={() => setSelectedTab(idx)}
          >
            {tab}
            <div className={styles.highlight} />
          </div>
        ))}
      </div>

      <div>
        {selectedTab === 0 ? (
          <>
            {canAccessActionHub
              && hasActions
              && !isTacTClient(isExternal, packageId)
              && !jobIsProcessing(job)
              && !(hasExpired && isExternal)
              && (
                <div className={styles.content} ref={actionsRef}>
                  <CurrentActions
                    job={job}
                    uploaders={{}}
                    user={user}
                    validations={validations}
                  />
                </div>
              )}
          </>
        ) : (
          <>
            {!_isFetchedActions && _isFetchingActions && <LoadingBox className={styles.loading} />}
            {_fetchingActionsError && <p>There was an error loading the actions for your job. Please try again later.</p>}
            {canRenderActions && showPreviousActions && (
              <ActionGroup
                showHeader
                actions={previousActions}
                job={job}
              />
            )}

            {previousActions && previousActions.length === 0 && (
              <p>Archived actions from prior runs of this job will be found here.</p>
            )}
          </>
        )}
      </div>
    </Container>
  )
}

Actions.propTypes = propTypes

export default Actions
