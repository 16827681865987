import { validateOrApplyValidation } from 'actionHub/utils/validations'
import { objectFromArray, objectFromArrayWithFunctions } from 'utils/objects'

export const revisedStatusCount = (statusObject, oldTasks, newId) => {
  if (statusObject.id === newId) {
    return statusObject.count + oldTasks.length
  }
  return statusObject.count - oldTasks.filter(x => x.statusId === statusObject.id).length
}

export const revisedActionStateCount = (actionStateObject, oldTasks, newId) => {
  if (actionStateObject.id === newId) {
    return actionStateObject.count + oldTasks.length
  }
  return actionStateObject.count - oldTasks.filter(x => x.taskActionId === actionStateObject.id).length
}

export const convertTaskResponseToTask = (state, x) => ({
  ...state.tasks[x._id],
  _id: x._id,
  taskData: x,
  assignedTo: x._assignedTo,
  priorityId: x._priorityId,
  taskActionId: x._taskActionId,
  statusId: x._statusId,
  tagId: x._tagId,
  metaId: x._metaId,
})

export { validateOrApplyValidation, objectFromArray, objectFromArrayWithFunctions }
