import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import PropTypes from 'prop-types'
import AggregationBar from 'actionHub/views/AggregationBar/AggregationBar'
import useAction from 'actionHub/hooks/useAction'
import { hot } from 'react-hot-loader/root'
import TaskList from 'actionHub/views/TaskList'
import { jobPermissions } from 'utils/permissions'
import { useJobPropTypes } from 'hooks/useJob'
import BulkUpdateModal from 'actionHub/views/BulkUpdateModal/BulkUpdateModal'
import { useDispatch, useSelector } from 'react-redux'
import { actionHubUpdateSelectedTasks } from 'actionHub/redux/actions'
import Loading from 'components/Loading'
import constants from 'constants'
import { modalCreate, modalShow } from 'actions/modals'
import useApp from 'hooks/useApp'
import EditDetailsForm from 'actionHub/views/EditDetailsForm'

function ActionTasks({ useJob, match: { params: { actionSetId } } }) {
  const {
    onTabChange, tasks, columns, statuses, isCurrent,
    goToTask,
    priorities, isFetching,
    setTasksAssigned, setTasksPriority, setTasksActionState,
    filterStatus, fetchNextPage, mainAggregation, selectedTab,
    allowTags, actionStates, addNewTask, detailColumns,
  } = useAction(actionSetId)

  const {
    deloitteTeam, userHasJobPermission,
  } = useJob

  const { user } = useApp()
  const selectedTasks = useSelector(state => state.actionHub.selectedTasks)
  const taskListSearchQuery = useSelector(state => state.actionHub.taskListSearchQuery)
  const [localSelectedTasks, setLocalSelectedTasks] = useState(Object.values(selectedTasks).map(task => task._id))
  const dispatch = useDispatch()
  const modals = useSelector(state => state.modals)
  const isBulkUpdateLoading = useSelector(state => state.actionHub._isSelectedTasksUpdating)
  const MODAL_ID = constants.MODAL_BULK_ACTION_UPDATES
  const ADDTASKMODAL_ID = constants.MODAL_ADD_TASK

  useEffect(() => {
    dispatch(actionHubUpdateSelectedTasks(actionSetId, localSelectedTasks))
  }, [actionSetId, dispatch, localSelectedTasks])

  const canEditActions = userHasJobPermission(jobPermissions.actionHubEdit)

  const createModal = useCallback(() => {
    if (modals && !modals.modalNames.includes(MODAL_ID)) {
      dispatch(modalCreate(MODAL_ID))
    }
  }, [MODAL_ID, dispatch, modals])

  const onHandleOpenModal = useCallback((isBulkUpdate, taskId) => {
    if (isBulkUpdate) {
      dispatch(actionHubUpdateSelectedTasks(actionSetId, localSelectedTasks))
      dispatch(modalShow(MODAL_ID))
    } else {
      goToTask(taskId)
    }
  }, [MODAL_ID, actionSetId, dispatch, goToTask, localSelectedTasks])

  const handleAddTaskClick = useCallback(() => {
    if (modals && !modals.modalNames.includes(ADDTASKMODAL_ID)) {
      dispatch(modalCreate(ADDTASKMODAL_ID))
    }
    dispatch(modalShow(ADDTASKMODAL_ID))
  }, [ADDTASKMODAL_ID, dispatch, modals])

  const filteredData = useMemo(() => {
    let filteredTasks = tasks
    switch (selectedTab) {
      case 'assignedToMe':
        filteredTasks = tasks.filter(x => x.assignedTo === user.id)
        break
      case 'incomplete':
        filteredTasks = tasks.filter(x => x.statusId !== 3)
        break
      case 'toBeReviewed':
        filteredTasks = tasks.filter(x => x.taskActionId === 1)
        break
      case 'excluded':
        filteredTasks = tasks.filter(x => x.taskActionId === 3)
        break
      case 'noTag':
        filteredTasks = tasks.filter(x => x.tagId === null)
        break
      default:
        break
    }

    if (!taskListSearchQuery || taskListSearchQuery.length < 3) {
      return filteredTasks
    }

    filteredTasks = filteredTasks.filter(item => Object.values(item).some(value => (typeof value === 'string' && value.toLowerCase().includes(taskListSearchQuery.toLowerCase()))
      || (typeof value === 'object' && value !== null && Object.values(value).some(
        nestedValue => typeof nestedValue === 'string' && nestedValue.toLowerCase().includes(taskListSearchQuery.toLowerCase()),
      ))))

    return filteredTasks
  }, [taskListSearchQuery, selectedTab, tasks, user.id])

  const aggregation = {
    ...mainAggregation,
    denominator: mainAggregation.totalActions,
    filteredTasksCount: filteredData.length || 0,
    value: statuses.find(x => x.id === 3).count,
    valueDescription: '$VALUE$ reviewed',
    secondaryValue: statuses.find(x => x.id === 0).count,
    secondaryValueDescription: '$VALUE$ excluded from review',
  }

  if (isFetching || isBulkUpdateLoading) {
    return <Loading pageLoading />
  }

  return (
    <>
      <AggregationBar aggregations={[aggregation]} handleAddTaskClick={handleAddTaskClick} />
      <TaskList
        actionSetId={actionSetId}
        actionStates={actionStates}
        canLoadMore={filterStatus.hasNext && !isFetching}
        columns={columns}
        disabled={!isCurrent || !canEditActions}
        goToTask={goToTask}
        members={deloitteTeam}
        onHandleOpenModal={onHandleOpenModal}
        onLoadMore={fetchNextPage}
        onTabChange={onTabChange}
        priorities={priorities}
        selectedFilter={selectedTab}
        selectedTasks={localSelectedTasks}
        setSelectedTasks={(taskIds) => {
          createModal()
          setLocalSelectedTasks(taskIds)
        }}
        setTasksActionState={setTasksActionState}
        setTasksAssigned={setTasksAssigned}
        setTasksPriority={setTasksPriority}
        showTags={allowTags}
        statuses={statuses}
        tasks={filteredData}
      />
      {localSelectedTasks.length > 0 && modals.modalNames.includes(MODAL_ID)
        && (
        <BulkUpdateModal
          actionSetId={actionSetId}
          modalId={MODAL_ID}
          selectedTasks={localSelectedTasks}
          setSelectedTasks={setLocalSelectedTasks}
          useJob={useJob}
        />
        )}
      {detailColumns && modals.modalNames.includes(ADDTASKMODAL_ID)
        && (
        <EditDetailsForm
          isNewForm
          columns={detailColumns}
          modalId={ADDTASKMODAL_ID}
          onSaveClick={addNewTask}
        />
        )}
    </>
  )
}

ActionTasks.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      actionSetId: PropTypes.string,
    }),
  }).isRequired,
  useJob: useJobPropTypes.isRequired,
}

export default hot(ActionTasks)

